// @flow
import EnvConfig from 'env-config';
import getScriptHostName from 'common/utils/getScriptHostName';

const getConfig = (): any => {
    if (EnvConfig.env !== 'devo') {
        return EnvConfig;
    }

    const hostname = getScriptHostName();
    // Basically here we are changing the EndPoint to correct devo based on subdomain
    // example: for devo-3.locus-dev.com, replacing devo with devo-3 in api endpoint
    // Assumption here: Only dashboardEnpoint config is different for each devo rest are same
    const [subdomain] = hostname.split('.');
    const devo: string = /^devo(-\d+|-coverage)?$/.test(subdomain)
        ? subdomain
        : EnvConfig.devo;

    // NOTE: in devo.json endPoint is template with {devo} as placeholder
    const dashboardEndPoint = EnvConfig.dashboardEndPoint.replace(
        '{devo}',
        devo
    );
    const apiEndPoint = EnvConfig.apiEndPoint.replace('{devo}', devo);
    const netOptEndPoint = EnvConfig.netOptEndPoint.replace('{devo}', devo);
    const customViaShHost = EnvConfig.customViaShHost.replace('{devo}', devo);
    const mfeEndPoints = Object.keys(EnvConfig.mfeEndPoints).reduce(
        (acc, curr) => {
            const processedEndPoint = EnvConfig.mfeEndPoints[curr].replace(
                '{devo}',
                devo
            );
            return { ...acc, [curr]: processedEndPoint };
        },
        {}
    );
    const getOmsDevo = devo => {
        switch (devo) {
            case 'devo-4':
                return 'devo';
            case 'devo-6':
                return 'devo-2';
            case 'devo-15':
                return 'devo-3';
            case 'devo-19':
                return 'devo-4';
            case 'devo-coverage':
                return 'devo-coverage';
            default:
                return 'devo';
        }
    };
    const omsEndPoint = EnvConfig.omsEndPoint.replace(
        '{devo}',
        getOmsDevo(devo)
    );
    const contractsEndPoint = EnvConfig.contractsEndPoint.replace(
        '{devo}',
        'devo'
    );

    const getPlatformDevo = devo => {
        switch (devo) {
            case 'devo-2':
            case 'devo-3':
            case 'devo-4':
            case 'devo-5':
            case 'devo-6':
            case 'devo-14':
            case 'devo-15':
            case 'devo-19':
            case 'devo-20':
            case 'devo-22':
            case 'devo-23':
            case 'devo-24':
            case 'devo-25':
                return devo;
            default:
                return 'devo';
        }
    };
    const platformEndPoint = EnvConfig.platformEndPoint.replace(
        '{devo}',
        getPlatformDevo(devo)
    );

    const getOrchestratorDevo = devo => {
        switch (devo) {
            case 'devo-24':
            case 'devo-25':
                return devo;
            default:
                return 'devo';
        }
    };
    const orchestratorEndPoint = EnvConfig.orchestratorEndPoint.replace(
        '{devo}',
        getOrchestratorDevo(devo)
    );

    const getMultilegDevo = devo => {
        switch (devo) {
            default:
                return 'devo';
        }
    };

    const multilegEndPoint = EnvConfig.multilegEndPoint.replace(
        '{devo}',
        getMultilegDevo(devo)
    );

    return {
        ...EnvConfig,
        devo,
        dashboardEndPoint,
        apiEndPoint,
        netOptEndPoint,
        contractsEndPoint,
        customViaShHost,
        omsEndPoint,
        platformEndPoint,
        orchestratorEndPoint,
        multilegEndPoint,
        mfeEndPoints
    };
};

export default (getConfig(): typeof EnvConfig);
